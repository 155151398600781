// import { GoogleLogo } from '@app/assets/icons';
import Config from '@/config';
import AuthService from '@/services/auth.service';
import { sentryCaptureException } from '@/utility';
import React from 'react';
import {
  GoogleCredentialResponse,
  GoogleLogin,
  GoogleOAuthProvider,
} from '@react-oauth/google';

const clientId = Config.SIGNIN_CLIENT_ID;

interface IProps {
  onSuccessLogin: () => void;
}

const GoogleLoginButton: React.FC<IProps> = (props) => {
  // const [disabled, setDisabled] = React.useState(false);
  const handleSuccess = async (data: GoogleCredentialResponse) => {
    // setDisabled(true);
    try {
      const authService = new AuthService();
      const authInfo = await authService.googleLogin({
        tokenId: data.credential,
      });

      authService.saveAuth(authInfo);
      props.onSuccessLogin();
      // setDisabled(false);
    } catch (error) {
      // do nothing
      sentryCaptureException(error);
      alert('Unable to login with Google');
    }
  };

  const handleError = () => {
    // do nothing
  };

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <GoogleLogin onSuccess={handleSuccess} onError={handleError} />
    </GoogleOAuthProvider>
  );
};

export default GoogleLoginButton;
