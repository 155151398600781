import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useEffect, useState } from 'react';

import CloseIcon from '@/icons/close';
import EventService from '@/services/event.service';
import GoogleLoginButton from './google-login-button.component';
import router from 'next/router';
import Link from 'next/link';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { useFormik } from 'formik';
import NetworkService from '@/services/network.service';

const BulletPoint: React.FC = (props) => {
  return (
    <div className="py-2 flex">
      <span className="text-green-400 pr-2">✓</span>
      {props.children}
    </div>
  );
};

interface IModelState {
  open: boolean;
  disabled: boolean;
}

export const SignInModal = () => {
  const [open, setOpen] = useState(false);
  const [disableClose, setDisableClose] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  useEffect(() => {
    const handleFn = (state: IModelState | boolean) => {
      if (typeof state === 'boolean') {
        setOpen(state);
        setDisableClose(false);
      } else {
        setOpen(state.open);
        setDisableClose(state.disabled);
      }
    };

    EventService.addListener('CHANGE_SIGNIN_MODEL_STATE', handleFn);

    return function clean() {
      EventService.removeListener('CHANGE_SIGNIN_MODEL_STATE', handleFn);
    };
  }, []);

  const handleClose = () => {
    if (disableClose) {
      return;
    }
    setOpen(false);
  };

  const handleSuccess = () => {
    setOpen(false);
  };

  const handleLink = (e: React.MouseEvent, link: string) => {
    e.preventDefault();
    setOpen(false);
    router.push(link);
  };

  const handleshowConfirmDialog = () => {
    setOpen(false);
    setShowConfirmDialog(true);
  };

  return (
    <React.Fragment>
      <Transition appear show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto backdrop-filter backdrop-blur-sm"
          onClose={handleClose}
        >
          <div className="min-h-screen text-center md:px-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50" />
            </Transition.Child>

            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block overflow-hidden text-left align-middle transition-all transform bg-white h-screen w-full md:h-auto md:min-w-96 md:max-w-4xl md:shadow-xl md:rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-semibold leading-6 text-gray-900 bg-gray-50 px-6 py-4 flex items-center justify-between"
                >
                  <span>Sign In</span>
                  {!disableClose && (
                    <button onClick={handleClose}>
                      <CloseIcon className="h-5 w-5" />
                    </button>
                  )}
                </Dialog.Title>
                <div>
                  <div className="px-6 py-4 flex flex-col md:flex-row items-center justify-items-center md:items-start md:justify-items-start">
                    <div className="px-8 md:px-12 pb-12 flex flex-col max-w-md">
                      <h4 className="font-bold text-lg">
                        Why do I have to sign in?
                      </h4>
                      <p className="my-4">
                        Local real estate rules require that you sign up to view
                        sold property information.
                      </p>
                      <div>
                        <BulletPoint>
                          Access sold property information
                        </BulletPoint>
                        <BulletPoint>
                          Be the first to know when new properties are available
                        </BulletPoint>
                        <BulletPoint>
                          Keep track of your favourite listings
                        </BulletPoint>
                      </div>
                    </div>
                    <div className="md:hidden border-b w-56 border-gray-300 mt-3 mb-4 md:border-b-0"></div>
                    <div className="max-w-md px-8 md:px-0 lg:px-8 md:w-60 flex flex-1 flex-col mb-4">
                      <div>
                        <LoginForm onSuccess={handleshowConfirmDialog} />
                      </div>
                      <div className="py-2 text-center">
                        <span className="text-xs text-gray-500">Or</span>
                      </div>
                      <div className="flex flex-col">
                        <GoogleLoginButton onSuccessLogin={handleSuccess} />
                      </div>
                      <div className="mt-4 text-sm">
                        By signin you acknowledge that you have read and agree
                        to our{' '}
                        <Link href="/tos" prefetch={false}>
                          <a
                            className="text-primary-500"
                            onClick={(e) => handleLink(e, '/tos')}
                          >
                            terms of service
                          </a>
                        </Link>{' '}
                        and{' '}
                        <Link href="/privacy" prefetch={false}>
                          <a
                            className="text-primary-500"
                            onClick={(e) => handleLink(e, '/privacy')}
                          >
                            privacy policy
                          </a>
                        </Link>
                        .
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
      <Transition appear show={showConfirmDialog} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto backdrop-filter backdrop-blur-sm"
          onClose={handleClose}
        >
          <div className="min-h-screen text-center md:px-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50" />
            </Transition.Child>

            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block overflow-hidden text-left align-middle transition-all transform bg-white h-screen w-full md:h-auto md:min-w-96 md:max-w-xl md:shadow-xl md:rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-semibold leading-6 text-gray-900 bg-gray-50 px-6 py-4 flex items-center justify-between"
                >
                  <span>Sign In</span>
                  {!disableClose && (
                    <button onClick={() => setShowConfirmDialog(false)}>
                      <CloseIcon className="h-5 w-5" />
                    </button>
                  )}
                </Dialog.Title>
                <div className="py-10 px-6">
                  <h4 className="text-lg font-bold">Please check your email</h4>
                  <p className="my-4">
                    We&apos;ve sent you a verifiation email. Please click on the
                    link you received in the email to complete your signin.
                  </p>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </React.Fragment>
  );
};

interface LoginFormData {
  name: string;
  email: string;
  phone: string;
}

const loginFormInitialValue: LoginFormData = {
  name: '',
  email: '',
  phone: '',
};

interface LoginFormProps {
  onSuccess: () => void;
}

const LoginForm: React.FC<LoginFormProps> = (props) => {
  const { handleBlur, handleChange, handleSubmit, isSubmitting } = useFormik({
    initialValues: loginFormInitialValue,
    onSubmit: async (values) => {
      try {
        const networkService = new NetworkService();
        await networkService.post('/api/send-login-link', {
          email: values.email,
          name: values.name,
          phone: values.phone,
        });
        props.onSuccess();
      } catch (error) {
        console.error(error);
        alert(error.message);
      }
    },
  });

  return (
    <form onSubmit={handleSubmit}>
      <div className="space-y-2">
        <div>
          <Input
            placeholder="Name *"
            variant="outlined"
            margin="dense"
            required
            name="name"
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </div>
        <div>
          <Input
            placeholder="Email *"
            variant="outlined"
            margin="dense"
            required
            type="email"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </div>
        <div>
          <Input
            placeholder="Phone"
            variant="outlined"
            margin="dense"
            type="tel"
            name="phone"
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </div>
        <div>
          <Button
            type="submit"
            className="rounded w-full"
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            Continue
          </Button>
        </div>
      </div>
    </form>
  );
};
